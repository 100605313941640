import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isMergeDocument: false,
      blankTemplatesData: [
        {
          filename: "page_no_1.pdf",
          source_filename: "0fd406fa-6833-463b-aade-171ec3694353.pdf",
        },
        {
          filename: "page_no_2.pdf",
          source_filename: "21acb043-a936-4399-93c8-65e987361905.pdf",
        },
        {
          filename: "page_no_3.pdf",
          source_filename: "8edab929-dfde-47ba-adf7-c00d63520a27.pdf",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("documentOperations", [
      "getPresignedURLGenerationStatus",
      "getPresignedURLsData",
      "getBlankFileCopyData",
      "getBlankFileCopyStatus",
      "getPresignedURLsForExistedDocsData",
      "getDownloadURLsData",
    ]),
  },
  methods: {
    //***addPageOnBottom***
    addPageOnBottomEvent() {
      this.$confirm("Are you sure to add page? ", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async () => {
        this.documentDataLoading = true;
        this.loadingText = "Page adding...";
        let params = {};

        params.page = this.allPdfData[this.total_documents - 1].pages;
        this.isEditingDocument = true;
        this.isAddingPage = true;
        this.isInitialRender = true;
        this.deleted_pages = [];
        this.rotations = [];

        //** MIXIN**
        await this.addPageToDocument(params, this.total_documents - 1);

        if (this.getAddPageStatus) {
          this.allPdfData = [];
          await this.renderAllDocuments(false);
        } else {
          this.$message.error("Oops, something went wrong!");
          this.documentDataLoading = false;
        }
      });
    },

    async addPageEvent(i, j) {
      try {
        this.$confirm("Are you sure to add page? ", "Warning", {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then(async () => {
          this.documentDataLoading = true;
          this.loadingText = "Page adding...";
          let params = {};

          params.page = i - 1;

          //** MIXIN**
          await this.addPageToDocument(params, j);

          if (this.getAddPageStatus) {
            this.currentSelectedPageIndex = i;
            this.currentSelectedDocIndex = j;
            this.getAffectedFieldsAdd();
            this.isEditingDocument = true;
            this.isAddingPage = true;
            this.isInitialRender = true;
            this.deleted_pages = [];
            this.allPdfData = [];
            this.rotations = [];
            await this.renderAllDocuments(false);
          } else {
            this.$message.error("Oops, something went wrong!");
            this.documentDataLoading = false;
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    async rotateLeftEvent(i, j) {
      // let id = `${j}_canvas_${i}`;
      // document.getElementById(id).focus();
      this.currentSelectedPageIndex = i;
      this.currentSelectedDocIndex = j;
      this.documentDataLoading = true;
      this.isLeftRotate = true;
      this.isEditingDocument = true;

      let rotations = this.rotations.filter((e) => e.document_id == j);
      rotations = rotations.sort((a, b) => a.page - b.page);

      if (rotations[i - 1].rotate == 0) {
        rotations[i - 1].rotate = 270;
      } else {
        rotations[i - 1].rotate = rotations[i - 1].rotate - 90;
      }

      this.updateFieldPositionsRotations();

      this.saveRotations(rotations, j);
    },
    async rotateRightEvent(i, j) {
      // let id = `${j}_canvas_${i}`;
      // document.getElementById(id).focus();
      this.currentSelectedPageIndex = i;
      this.currentSelectedDocIndex = j;
      this.documentDataLoading = true;
      this.isRightRotate = true;
      this.isEditingDocument = true;

      let rotations = this.rotations.filter((e) => e.document_id == j);
      rotations = rotations.sort((a, b) => a.page - b.page);
      if (rotations[i - 1].rotate == 360) {
        rotations[i - 1].rotate = 90;
      } else {
        rotations[i - 1].rotate = rotations[i - 1].rotate + 90;
      }

      this.updateFieldPositionsRotations();
      this.saveRotations(rotations, j);
      // this.pdfData.getPage(i).then(this.rotateRenderPage);
    },
    //save rotations
    async saveRotations(rotations, j) {
      let params = {};
      let newRotations = [];
      rotations.forEach((el) => {
        const data = (({ page, rotate }) => ({ page, rotate }))(el);
        newRotations.push(data);
      });
      params.keepFileID = true;
      params.stampByTsa = false;
      params.pdfSignatures = [];
      params.defaultScale = 1;
      params.pdfTexts = [];
      params.pdfImages = [];
      params.pdfRecs = [];
      params.pdfDeletePages = [];
      params.pdfRotatePages = newRotations;
      params.addDocId = false;
      params.addPageNumber = false;

      //** MIXIN**
      await this.modifyDocumentPages(params, j);

      this.rotations = [];
      this.isInitialRender = true;
      this.deleted_pages = [];
      this.rotations = [];
      this.allPdfData = [];
      this.scale = 0;

      await this.renderAllDocuments(false);
    },

    // **** deleting page ****
    async onDeletePageEvent(i, j) {
      this.$confirm("Are you sure to delete this page ? ", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async () => {
        this.deleted_pages.push(i);
        let params = {};
        params.keepFileID = true;
        params.pdfSignatures = [];
        params.defaultScale = 1;
        params.pdfTexts = [];
        params.pdfImages = [];
        params.pdfDeletePages = this.deleted_pages;
        params.pdfRotatePages = [];
        params.pdfRecs = [];
        params.addDocId = false;
        params.addPageNumber = false;
        this.documentDataLoading = true;
        this.loadingText = "Deleting page...";
        this.currentSelectedPageIndex = i;
        this.currentSelectedDocIndex = j;
        this.updateFieldPositionsDelete();

        //** MIXIN**
        await this.modifyDocumentPages(params, j);

        this.currentSelectedPageIndex = i;
        this.currentSelectedDocIndex = j;
        this.isEditingDocument = true;
        this.isDeletingPage = true;
        this.deleted_pages = [];
        this.rotations = [];
        this.allPdfData = [];
        this.scale = 0;
        this.isInitialRender = true;
        await this.renderAllDocuments(false);
      });
    },
    async mergeOrUploadFile() {
      // we need to upload each document parallelly
      let {
        uploadURls,
        docPaths,
        mergedDoc,
        downloadUrls,
        convertUploadUrls,
        convertDownloadUrls,
        filenames,
      } = await this.getFileS3UploadAndDownloadURLs(
        this.files,
        this.isMergeDocument
      );

      this.loading = true;
      this.loadingText = "Uploading Documents....";
      // we need to upload every document parallely to s3 using presigned URL
      await this.uplaodDocumentsToS3PresignedURLs(uploadURls, this.files);

      /**
             *1)  here we will get all uploaded filed download urls  
            to convert we will get only non pdf files in convertedUpload URL and convertDownlad URL
            2) We need to convrtabler download urls and upload urls * /
            3)  to merge all docuemnts we need to provide all pdf and non pdf files download urls 
            4) so merge need to in same order */

      /** convertble files downlaod urls will come alogs with pdf download urls we need to seperage non pdf download urls from download urls**/
      let rawFileDownloadURLS = [];
      /** we need insert pdf and converted non pdf download urls into files  */
      let converterCompletedFilesURLs = [];
      let fileExtns = []; /** non pdf file extns for tuvans api */
      let notPDFIndex = 0; /** to travse only non pdf convert urls */

      // we need to check any converted paths existed or not
      if (convertUploadUrls && convertUploadUrls.length) {
        filenames.forEach((doc, index) => {
          //let [docname, ext] = doc.split(".")
          let docname = "",
            ext = "";
          const dotIndex = doc.lastIndexOf(".");
          if (dotIndex !== -1) {
            docname = doc.substring(0, dotIndex);
            ext = doc.substring(dotIndex + 1);
          } else {
            console.log("No file extension found.");
            [docname, ext] = doc.split(".");
          }
          console.log(docname);
          if (ext != "pdf") {
            rawFileDownloadURLS.push(downloadUrls[index]);
            fileExtns.push(ext);
            converterCompletedFilesURLs.push(convertDownloadUrls[notPDFIndex]);
            notPDFIndex = notPDFIndex + 1;
          } else {
            converterCompletedFilesURLs.push(downloadUrls[index]);
          }
        });

        await this.convertDocumentsToPdf(
          rawFileDownloadURLS,
          convertUploadUrls,
          fileExtns
        );
      }

      if (this.isMergeDocument && this.files && this.files.length > 1) {
        if (converterCompletedFilesURLs && converterCompletedFilesURLs.length) {
          // we need to call merge document procecss v4 apis here
          this.mergeAllDocsV5(
            converterCompletedFilesURLs,
            mergedDoc.upload_url
          );
          // we are replacing doc paths with merged one
        } else {
          // we need to call merge document procecss v4 apis here
          this.mergeAllDocsV5(downloadUrls, mergedDoc.upload_url);
          // we are replacing doc paths with merged one
        }

        docPaths = [mergedDoc.doc_path];
      }

      this.uploadSingleFile(docPaths);
      this.removeDocumentFields(docPaths);
      this.loading = false;
    },

    async removeDocumentFields(docPaths) {
      await this.$store.dispatch("documentOperations/removePdfFields", {
        pdfUrls: docPaths,
      });
    },

    async uploadFileForAttachments(files) {
      // we need to upload each document parallelly
      let { uploadURls, docPaths } = await this.getFileS3UploadAndDownloadURLs(
        files,
        false,
        true
      );

      this.loading = true;
      this.loadingText = "Uploading Documents....";
      // we need to upload every document parallely to s3 using presigned URL
      await this.uplaodDocumentsToS3PresignedURLs(uploadURls, files);

      this.loading = false;
      return docPaths;
    },

    async getFileS3UploadAndDownloadURLs(
      files,
      merge = false,
      attachments = false
    ) {
      let filenames = files.map((e) => e.raw.name);
      await this.$store.dispatch("documentOperations/getPresignedURLForDocs", {
        filenames,
        merge_filename: merge,
        attachments,
      });

      if (this.getPresignedURLGenerationStatus) {
        let data = {
          uploadURls: this.getPresignedURLsData.upload_urls,
          downloadUrls: this.getPresignedURLsData.download_urls,
          docPaths: this.getPresignedURLsData.doc_paths,
          mergeDocumentURL: this.getPresignedURLsData.merged,
        };

        if (this.getPresignedURLsData.merged_doc) {
          data.mergedDoc = this.getPresignedURLsData.merged_doc;
        }

        if (this.getPresignedURLsData.converted_upload_urls) {
          data.convertUploadUrls =
            this.getPresignedURLsData.converted_upload_urls;
        }
        if (this.getPresignedURLsData.converted_download_urls) {
          data.convertDownloadUrls =
            this.getPresignedURLsData.converted_download_urls;
        }

        data.filenames = filenames;

        return data;
      } else {
        this.$messages.error("Failed at upload document");
      }

      return this.getPresignedURLGenerationStatus;
    },
    async uplaodDocumentsToS3PresignedURLs(urls, files) {
      let allProcess = [];

      urls.forEach((url, index) => {
        allProcess.push(
          this.$store.dispatch("documentOperations/uploadDocumentsToS3", {
            url,
            file: files[index].raw,
          })
        );
      });

      await Promise.all(allProcess);
    },
    async mergeAllDocsV5(downloadURLs, mergeDocUploadURL) {
      await this.$store.dispatch("documentOperations/mergeDocuments", {
        downloadUrl: downloadURLs,
        uploadUrl: mergeDocUploadURL,
      });
    },

    async getCopyDocumentsdata(data) {
      await this.$store.dispatch(
        "documentOperations/getBlackFileCopyS3URL",
        data
      );
      if (this.getBlankFileCopyStatus) {
        return this.getBlankFileCopyData;
      }
      return null;
    },
    async modifyDocumentPages(params, index) {
      let currentDocPath = this.document_url_relative_paths[index];

      await this.$store.dispatch(
        "documentOperations/getPresignedURLForExistedDocs",
        {
          processed_documents: [currentDocPath],
        }
      );

      let uploadAndDownloadURLS = this.getPresignedURLsForExistedDocsData;

      params.uploadUrl = uploadAndDownloadURLS.upload_urls[0];
      params.downloadUrl = uploadAndDownloadURLS.download_urls[0];

      await this.$store.dispatch("documentOperations/fileTheUploadv5", params);

      this.document_url_paths[index] = uploadAndDownloadURLS.download_urls[0];
    },
    async addPageToDocument(params, index) {
      let currentDocPath = this.document_url_relative_paths[index];

      await this.$store.dispatch(
        "documentOperations/getPresignedURLForExistedDocs",
        {
          processed_documents: [currentDocPath],
        }
      );

      let uploadAndDownloadURLS = this.getPresignedURLsForExistedDocsData;

      params.uploadUrl = uploadAndDownloadURLS.upload_urls[0];
      params.downloadUrl = uploadAndDownloadURLS.download_urls[0];

      await this.$store.dispatch(
        "documentOperations/addPageToDocumentv5",
        params
      );

      this.document_url_paths[index] = uploadAndDownloadURLS.download_urls[0];
    },

    async getImageDownloadUrl(paths) {
      let params = {
        paths,
      };
      await this.$store.dispatch(
        "documentOperations/fetchDownloadURLsData",
        params
      );
      if (this.getDownloadURLsData?.download_urls?.[0]) {
        return this.getDownloadURLsData.download_urls[0];
      } else {
        return "";
      }
    },
    async downloadFilesFromS3Paths(paths, names, mainTitle = "") {
      let params = {
        paths,
      };

      await this.$store.dispatch(
        "documentOperations/fetchDownloadURLsData",
        params
      );

      let downloadURLs = this.getDownloadURLsData.download_urls;

      for (let i = 0; i < downloadURLs.length; i++) {
        this.downloadFileFromURLWithName(downloadURLs[i], names[i], mainTitle);
      }
    },
    async getDownloadFilesURLFromS3Paths(paths) {
      let params = {
        paths,
      };

      await this.$store.dispatch(
        "documentOperations/fetchDownloadURLsData",
        params
      );

      let downloadURLs = this.getDownloadURLsData.download_urls;

      return downloadURLs;
    },
    async downloadFileFromURLWithName(url, file_name, mainTitle = "") {
      // Create a new URL object
      var newUrl = new URL(url);
      // Get different parts of the URL
      // var protocol = newUrl.protocol; // e.g., "https:"
      // var host = newUrl.host; // e.g., "www.example.com"
      var pathname = newUrl.pathname; // e.g., "/path/to/resource"
      // var search = newUrl.search; // e.g., "?param1=value1&param2=value2"

      /* Now get the file type of from file path */
      var segments = pathname.split("/");
      var lastSegment = segments[segments.length - 1];
      // Extract the file extension
      var fileExtension = lastSegment.split(".").pop();

      /* Original File name */
      var originalFileSegments = mainTitle ? mainTitle : file_name.split(".");
      var firstSegment = mainTitle
        ? originalFileSegments
        : originalFileSegments[0];

      //  console.log("File Name",file_name,pathname,firstSegment+"."+fileExtension)
      await fetch(url, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;

          fileLink.setAttribute("download", firstSegment + "." + fileExtension);

          document.body.appendChild(fileLink);

          fileLink.click();
        });
    },

    async convertDocumentsToPdf(downloadUrls, convertUploadUrls, fileExtns) {
      let params = [];
      downloadUrls.map((e, index) => {
        params.push({
          downloadUrl: downloadUrls[index],
          uploadUrl: convertUploadUrls[index],
          type: "." + fileExtns[index],
        });
      });

      await this.$store.dispatch(
        "documentOperations/uploadFilesToConvertv5",
        params
      );
    },

    async convertPdfURLtoBase64String(urlOrPdf) {
      const base64ToBrowser = (buffer) => {
        return window.btoa(
          [].slice
            .call(new Uint8Array(buffer))
            .map(function (bin) {
              return String.fromCharCode(bin);
            })
            .join("")
        );
      };

      let response = await fetch(urlOrPdf);
      let buffer = await response.arrayBuffer();

      let string = base64ToBrowser(buffer);
      console.log(string);
    },

    bufferToBase64String(buffer) {
      return window.btoa(
        [].slice
          .call(new Uint8Array(buffer))
          .map(function (bin) {
            return String.fromCharCode(bin);
          })
          .join("")
      );
    },
  },
};
